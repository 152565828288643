import styled from 'styled-components'

export const Wrapper = styled.div<{ customMargin: boolean }>`
  display: flex;
  flex-direction: row; 
  align-items: center;
  margin: ${({ customMargin }) => customMargin ? '0px auto 40px' : '0px auto'};

  @media screen and (min-width: 980px) {
    margin: 0;
  }
`

export const BaseText = styled.a`
  font-size: 18px;
  z-index: 10;
  font-weight: 700;
  text-decoration: none;
  margin-top: 15px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 25px;
    left: 50%;
    width: 0;
    height: 1px;
    opacity: 0;
    border-radius: 10px;
    transition: all 200ms ease-in-out;
  }

  &:hover::before {
    left: 0;
    width: 100%;
    opacity: 1;
  }
`

export const FirstText = styled(BaseText) <{ darkColor: boolean }>`
  color: ${({ darkColor }) => darkColor ? '#757575' : 'rgba(255, 255, 255, 0.7)'};

  &::before {
    background: ${({ darkColor }) => darkColor ? '#757575' : 'rgba(255, 255, 255, 0.7)'};
  }

  &:hover::before {
    width: 75%;
  }
`

export const SecontText = styled(BaseText) <{ darkColor: boolean }>`
  color: ${({ darkColor }) => darkColor ? '#212121' : '#F7F7F7'};
  margin-left: .5rem;

  &::before {
    background: ${({ darkColor }) => darkColor ? '#212121' : '#F7F7F7'};
  }
`