import styled from 'styled-components'

export interface IBackgroundProperties {
  sizeG?: SizeInterface,
  sizeM?: SizeInterface,
  positionG: SizeInterface,
  positionM: SizeInterface,
}

export interface SizeInterface {
  x?: number,
  y?: number
}

export const CategoryPageImage = styled.div<{
  backgroundImage: string,
  backgroundImageDesktop?: string
  backgroundSizeMobile: SizeInterface,
  backgroundPositionMobile: SizeInterface,
  backgroundSizeDesktop: SizeInterface,
  backgroundPositionDesktop: SizeInterface
}>`
  position: absolute;
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: ${props => `${props.backgroundSizeMobile.x}px ${props.backgroundSizeMobile.y}px`};
  background-position: ${props => `${props.backgroundPositionMobile.x}% ${props.backgroundPositionMobile.y}%`};
  min-height: 470px;
  max-height: 470px;
  width: 100%;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  z-index: 1;
  
  @media screen and (min-width: 980px) {
    background-image: url(${props => props.backgroundImageDesktop});
    min-width: 800px;
    max-width: calc(50vw + 430px);
    min-height: 410px;
    max-height: 410px;
    background-size: ${props => `${props.backgroundSizeDesktop.x}px ${props.backgroundSizeDesktop.y}px`};
    background-position: ${props => `${props.backgroundPositionDesktop.x}% ${props.backgroundPositionDesktop.y}%`};
    background-repeat: no-repeat;
    filter: blur(0px);
    -webkit-filter: blur(0px);
  }

  @media screen and (min-width: 1365px) {
    max-width: 1200px;
  }
`

export const PillowPageImage = styled.div<{
  backgroundImage: string,
  backgroundImageDesktop?: string
  backgroundSizeMobile: SizeInterface,
  backgroundPositionMobile: SizeInterface,
  backgroundSizeDesktop: SizeInterface,
  backgroundPositionDesktop: SizeInterface
}>`
  position: absolute;
  left: 0;
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: ${props => `${props.backgroundSizeMobile.x}px ${props.backgroundSizeMobile.y}px`};
  background-position: ${props => `${props.backgroundPositionMobile.x}% ${props.backgroundPositionMobile.y}%`};
  min-height: ${props => props.backgroundSizeMobile.y}px;
  max-height: ${props => props.backgroundSizeMobile.y}px;
  min-width: min(440px, 100vw);
  max-width: 100vw;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  z-index: 1;
  
  @media screen and (min-width: 400px) {
    left: unset;
    right: 0;
    margin-top: 10px;
    background-image: url(${props => props.backgroundImageDesktop});
    max-width: 100vw;
    min-width: 100vw;
    min-height: 450px;
    max-height: 450px;
    background-size: cover;
    background-position: ${props => `${props.backgroundPositionDesktop.x}% ${props.backgroundPositionDesktop.y}%`};

  }

  @media screen and (min-width: 980px) {
    scale: 1;
    margin-top: 0;
    left: unset;
    max-width: ${props => props.backgroundSizeDesktop.x}px;
    min-width: ${props => props.backgroundSizeDesktop.x}px;
    min-height: ${props => props.backgroundSizeDesktop.y}px;
    max-height: ${props => props.backgroundSizeDesktop.y}px;
    background-size: ${props => `${props.backgroundSizeDesktop.x}px ${props.backgroundSizeDesktop.y}px`};
    background-position: ${props => `${props.backgroundPositionDesktop.x}% ${props.backgroundPositionDesktop.y}%`};
    background-repeat: no-repeat;
    filter: blur(0px);
    -webkit-filter: blur(0px);
    right: 0;
  }
`