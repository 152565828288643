import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'

export interface CategoryType {
  name: CategoryNameEnum,
  color: CategoryColorEnum,
  image: CategoryImageEnum,
  subtitle: CategorySubtitleEnum,
  categoryBreadcrumb: CategoryBreadcrumbEnum,
  localeNamespaces?: LocaleNamespaces
}

export enum CategoryNameEnum {
  INVITED_AUTHORS = 'Autores convidados',
  TESTS_AND_QUIZES = 'Testes & Quizes',
  SLEEP_AND_CIENCE = 'Sono & ciência',
  SLEEP_AND_PERFORMANCE = 'Sono & performance',
  SLEEP_AND_HEALTH = 'sono & saúde',
  SLEEP_AND_CULTURE = 'sono & cultura',
  SLEEP_AND_AGE = 'sono & idade',
  SLEEP_AND_TECHNOLOGY = 'sono & tecnologia',
  HEALTH_AND_WELFARE = 'saúde & bem-estar',
  SLEEP_DISORDER = 'distúrbios do sono',
  PILLOW = 'travesseiros',
}

enum CategoryColorEnum {
  INVITED_AUTHORS = '#576267',
  TESTS_AND_QUIZES = '#576267',
  SLEEP_AND_CIENCE = '#0F50BF',
  SLEEP_AND_PERFORMANCE = '#fa8b36',
  SLEEP_AND_HEALTH = '#DDA53D',
  SLEEP_AND_CULTURE = '#2EA262',
  SLEEP_AND_AGE = '#5746AC',
  SLEEP_AND_TECHNOLOGY = '#2B95CF',
  HEALTH_AND_WELFARE = '#f8f8f8',
  SLEEP_DISORDER = '#cf403e',
  PILLOW = '#55AADD',
}

enum CategoryImageEnum {
  INVITED_AUTHORS = '/images/category0.webp',
  TESTS_AND_QUIZES = '/images/category1.webp',
  SLEEP_AND_CIENCE = '/images/category2.webp',
  SLEEP_AND_PERFORMANCE = '/images/category3.webp',
  SLEEP_AND_HEALTH = '/images/category4.webp',
  SLEEP_AND_CULTURE = '/images/category5.webp',
  SLEEP_AND_AGE = '/images/category6.webp',
  SLEEP_AND_TECHNOLOGY = '/images/category7.webp',
  HEALTH_AND_WELFARE = '/images/category8.webp',
  SLEEP_DISORDER = '/images/category9.webp',
  PILLOW = '/images/pillow-category-header.webp | /images/pillow-category-header-desktop.png'
}

enum CategorySubtitleEnum {
  INVITED_AUTHORS = 'invited-authors',
  TESTS_AND_QUIZES = 'test-and-quizes',
  SLEEP_AND_CIENCE = 'sleep-and-cience',
  SLEEP_AND_PERFORMANCE = 'sleep-and-performance',
  SLEEP_AND_HEALTH = 'sleep-and-health',
  SLEEP_AND_CULTURE = 'sleep-and-culture',
  SLEEP_AND_AGE = 'sleep-and-age',
  SLEEP_AND_TECHNOLOGY = 'sleep-and-technology',
  HEALTH_AND_WELFARE = 'health-and-welfare',
  SLEEP_DISORDER = 'sleep-disorder',
  PILLOW = 'pillow'
}

enum CategoryBreadcrumbEnum {
  INVITED_AUTHORS = 'Home > Autores Convidados',
  TESTS_AND_QUIZES = 'Home > Testes & Quizes',
  SLEEP_AND_CIENCE = 'Home > Sono & Ciência',
  SLEEP_AND_PERFORMANCE = 'Home > Sono & Performance',
  SLEEP_AND_HEALTH = 'Home > Sono & Saúde',
  SLEEP_AND_CULTURE = 'Home > Sono & Cultura',
  SLEEP_AND_AGE = 'Home > Sono & Idade',
  SLEEP_AND_TECHNOLOGY = 'Home > Sono & Tecnologia',
  HEALTH_AND_WELFARE = 'Home > Saúde & Bem-estar',
  SLEEP_DISORDER = 'Home > Distúrbios Do Sono',
  PILLOW = 'Home > Travesseiros',
}

type CategoryGroupType = {
  [key: string]: CategoryType
}

export const allCategoriesAtributes: CategoryGroupType = {
  'convidados': {
    'name': CategoryNameEnum.INVITED_AUTHORS,
    'color': CategoryColorEnum.INVITED_AUTHORS,
    'image': CategoryImageEnum.INVITED_AUTHORS,
    'subtitle': CategorySubtitleEnum.INVITED_AUTHORS,
    'categoryBreadcrumb': CategoryBreadcrumbEnum.INVITED_AUTHORS
  },
  'testes e quizes': {
    'name': CategoryNameEnum.TESTS_AND_QUIZES,
    'color': CategoryColorEnum.TESTS_AND_QUIZES,
    'image': CategoryImageEnum.TESTS_AND_QUIZES,
    'subtitle': CategorySubtitleEnum.TESTS_AND_QUIZES,
    'categoryBreadcrumb': CategoryBreadcrumbEnum.TESTS_AND_QUIZES
  },
  'sono e ciência': {
    'name': CategoryNameEnum.SLEEP_AND_CIENCE,
    'color': CategoryColorEnum.SLEEP_AND_CIENCE,
    'image': CategoryImageEnum.SLEEP_AND_CIENCE,
    'subtitle': CategorySubtitleEnum.SLEEP_AND_CIENCE,
    'categoryBreadcrumb': CategoryBreadcrumbEnum.SLEEP_AND_CIENCE
  },
  'sono e performance': {
    'name': CategoryNameEnum.SLEEP_AND_PERFORMANCE,
    'color': CategoryColorEnum.SLEEP_AND_PERFORMANCE,
    'image': CategoryImageEnum.SLEEP_AND_PERFORMANCE,
    'subtitle': CategorySubtitleEnum.SLEEP_AND_PERFORMANCE,
    'categoryBreadcrumb': CategoryBreadcrumbEnum.SLEEP_AND_PERFORMANCE
  },
  'sono e saúde': {
    'name': CategoryNameEnum.SLEEP_AND_HEALTH,
    'color': CategoryColorEnum.SLEEP_AND_HEALTH,
    'image': CategoryImageEnum.SLEEP_AND_HEALTH,
    'subtitle': CategorySubtitleEnum.SLEEP_AND_HEALTH,
    'categoryBreadcrumb': CategoryBreadcrumbEnum.SLEEP_AND_HEALTH
  },
  'sono e cultura': {
    'name': CategoryNameEnum.SLEEP_AND_CULTURE,
    'color': CategoryColorEnum.SLEEP_AND_CULTURE,
    'image': CategoryImageEnum.SLEEP_AND_CULTURE,
    'subtitle': CategorySubtitleEnum.SLEEP_AND_CULTURE,
    'categoryBreadcrumb': CategoryBreadcrumbEnum.SLEEP_AND_CULTURE
  },
  'sono e idade': {
    'name': CategoryNameEnum.SLEEP_AND_AGE,
    'color': CategoryColorEnum.SLEEP_AND_AGE,
    'image': CategoryImageEnum.SLEEP_AND_AGE,
    'subtitle': CategorySubtitleEnum.SLEEP_AND_AGE,
    'categoryBreadcrumb': CategoryBreadcrumbEnum.SLEEP_AND_AGE
  },
  'sono e tecnologia': {
    'name': CategoryNameEnum.SLEEP_AND_TECHNOLOGY,
    'color': CategoryColorEnum.SLEEP_AND_TECHNOLOGY,
    'image': CategoryImageEnum.SLEEP_AND_TECHNOLOGY,
    'subtitle': CategorySubtitleEnum.SLEEP_AND_TECHNOLOGY,
    'categoryBreadcrumb': CategoryBreadcrumbEnum.SLEEP_AND_TECHNOLOGY
  },
  'saúde e bem-estar': {
    'name': CategoryNameEnum.HEALTH_AND_WELFARE,
    'color': CategoryColorEnum.HEALTH_AND_WELFARE,
    'image': CategoryImageEnum.HEALTH_AND_WELFARE,
    'subtitle': CategorySubtitleEnum.HEALTH_AND_WELFARE,
    'categoryBreadcrumb': CategoryBreadcrumbEnum.HEALTH_AND_WELFARE
  },
  'distúrbios do sono': {
    'name': CategoryNameEnum.SLEEP_DISORDER,
    'color': CategoryColorEnum.SLEEP_DISORDER,
    'image': CategoryImageEnum.SLEEP_DISORDER,
    'subtitle': CategorySubtitleEnum.SLEEP_DISORDER,
    'categoryBreadcrumb': CategoryBreadcrumbEnum.SLEEP_DISORDER
  },
  'travesseiros': {
    'name': CategoryNameEnum.PILLOW,
    'color': CategoryColorEnum.PILLOW,
    'image': CategoryImageEnum.PILLOW,
    'subtitle': CategorySubtitleEnum.PILLOW,
    'categoryBreadcrumb': CategoryBreadcrumbEnum.PILLOW,
    'localeNamespaces': LocaleNamespaces.PILLOWS
  }
}