export enum LocaleNamespaces {
  ABOUT = 'about',
  ABOUT_US = 'about-us',
  COMMON = 'common',
  ERROR = 'error',
  FOOTER = 'footer',
  HEADER = 'header',
  NOTIFY_ME_MODAL = 'notify-me-modal',
  HOME = 'home',
  INSIGHTS = 'insights',
  INTERNATIONAL_LANDING_PAGE = 'international-landing-page',
  LANDING = 'landing',
  LEAD = 'lead',
  PRIVACY_POLICY = 'privacy-policy',
  SCIENCE = 'science',
  TECHNOLOGY = 'technology',
  FAQ = 'faq',
  PILLOWS = 'pillows'
}