import { useRouter } from 'next/router'
import { ReactElement } from 'react'
import { CategoryNameEnum, CategoryType } from '../../pages/Insights/category-atributes'
import { FirstText, SecontText, Wrapper } from './styles'


export const Breadcrumbs = (props: CategoryType) => {
  const router = useRouter()
  
  const getSlug = ():string => {
    if(typeof router.query.slug === 'undefined') {
      return '/travesseiros'
    }
    return `/insights/category/${router.query.slug[0]}`
  }

  const handleTextColor = (text: string): ReactElement => {
    const breadcrumbs = text.split('>')
    const shouldUseDarkColors = (props.name == CategoryNameEnum.HEALTH_AND_WELFARE)
    
    return breadcrumbs.length > 2
      ? <>
      </>
      : <>
        <FirstText darkColor={shouldUseDarkColors} href={'/'} >{`${breadcrumbs[0]} >`}</FirstText>
        <SecontText darkColor={shouldUseDarkColors} href={getSlug()}>{`${breadcrumbs[1]}`}</SecontText>
      </>
  }

  return (
    <Wrapper customMargin={props.name == CategoryNameEnum.SLEEP_AND_PERFORMANCE}>
      {handleTextColor(props.categoryBreadcrumb)}
    </Wrapper>
  )
}