import { CategoryContainer } from '../../molecules/CategoryContainer'
import { CategoryType } from '../../pages/Insights/category-atributes'
import { BackgroundColor, OverflowHandler } from './styles'

export const CategoryHeader = (props: CategoryType) => (
  <>
    <BackgroundColor backgroundColor={`${props.color}`} id="anchor">
      <CategoryContainer {...props}/>
      <OverflowHandler />
    </BackgroundColor>
  </>
)