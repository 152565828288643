import { useTranslation } from 'next-i18next'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { Breadcrumbs } from '../../atoms/Breadcrumbs'
import { CategoryMainImage } from '../../atoms/CategoryMainImage'
import { CategoryNameEnum, CategoryType } from '../../pages/Insights/category-atributes'
import { BlurryBackground, QuizButton, Subtitle, Title, Wrapper } from './styles'

export const CategoryContainer = (props: CategoryType) => {
  const { t: translate } = useTranslation(props.localeNamespaces ?? LocaleNamespaces.INSIGHTS)
  const child = <>
    <BlurryBackground backgroundColor={props.color} quizHeader={props.name == CategoryNameEnum.PILLOW} />
    <CategoryMainImage {...props} />
    <Breadcrumbs {...props} />
    <Title
      darkColor={props.name == CategoryNameEnum.HEALTH_AND_WELFARE}
      bigTitle={props.name == CategoryNameEnum.SLEEP_AND_PERFORMANCE}
    >
      {props.name}
    </Title>
    <Subtitle darkColor={props.name == CategoryNameEnum.HEALTH_AND_WELFARE}>
      {translate(props.subtitle)}
    </Subtitle>

  </>

  return (
    <>
      {props.name == CategoryNameEnum.PILLOW
        ? <Wrapper>
          {child}
          <QuizButton href="/teste-travesseiro">
            Faça o teste
          </QuizButton>
        </Wrapper>
        : <Wrapper>
          {child}
        </Wrapper>
      }
    </>
  )
}
